import * as React from 'react'

const Download: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 21h12M12 3v14m0 0 5-5m-5 5-5-5"
            />
        </svg>
    )
}

export { Download }
