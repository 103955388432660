// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer___footer___hMicS{padding:5px 10px;width:100%;background-color:var(--background);border-top:1px solid #c9d6df;position:fixed;bottom:0;left:0;right:0;z-index:1}.Footer___footer-links___eWCs7{font-size:14px;text-align:center;list-style:none;display:flex;justify-content:center;align-items:center}.Footer___footer-links___eWCs7 li{padding:0 20px}.Footer___footer-links___eWCs7 a{color:#52616b}\n", "",{"version":3,"sources":["webpack://./src/Module/Footer/Footer.scss","webpack://./src/Styles/colors.scss"],"names":[],"mappings":"AAEA,yBACI,gBAAiB,CACjB,UAAW,CACX,kCCsByC,CDrBzC,4BCCoB,CDApB,cAAe,CACf,QAAS,CACT,MAAO,CACP,OAAQ,CACR,SAAU,CACb,+BAGG,cAAe,CACf,iBAAkB,CAClB,eAAgB,CAChB,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CANvB,kCASQ,cAAe,CATvB,iCAaQ,aCnBgB","sourcesContent":["@import '../../Styles/colors';\n\n.footer {\n    padding: 5px 10px;\n    width: 100%;\n    background-color: var(--background);\n    border-top: 1px solid $color-gray-200;\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 1;\n}\n\n.footer-links {\n    font-size: 14px;\n    text-align: center;\n    list-style: none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    li {\n        padding: 0 20px;\n    }\n\n    a {\n        color: $color-gray-300;\n    }\n}\n","$color-white: #FFF;\n\n$color-black-100: #0000000d;\n$color-black-200: #0000008a;\n$color-black-300: #282c35;\n\n$color-gray-100: #f0f5f9;\n$color-gray-200: #c9d6df;\n$color-gray-300: #52616b;\n\n$theme-default: (\n  --background-100: $color-black-100,\n  --background: $color-white,\n\n  --color-100: $color-black-200,\n  --color: $color-black-300,\n);\n\n$theme-dark: (\n  --background-100: $color-gray-200,\n  --background: $color-black-300,\n\n  --color: $color-gray-200,\n  --color-100: $color-gray-200,\n);\n\n@function var($variable) {\n    @return unquote('var(' + $variable + ')');\n}\n"],"sourceRoot":""}]);
// Exports
export var footer = "Footer___footer___hMicS";
export var footerLinks = "Footer___footer-links___eWCs7";
export default ___CSS_LOADER_EXPORT___;
