import * as React from 'react'

import * as styles from './Mail.scss'

const Mail = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.626 511.627">
        <path
            className={styles.path}
            d="M498.208 68.235c-8.945-8.947-19.701-13.418-32.261-13.418H45.682c-12.562 0-23.318 4.471-32.264 13.418C4.471 77.18 0 87.935 0 100.499v310.633c0 12.566 4.471 23.312 13.418 32.257 8.945 8.953 19.701 13.422 32.264 13.422h420.266c12.56 0 23.315-4.469 32.261-13.422 8.949-8.945 13.418-19.697 13.418-32.257V100.499c-.001-12.564-4.469-23.319-13.419-32.264zm-23.13 342.89c0 2.475-.903 4.616-2.714 6.424-1.81 1.81-3.949 2.706-6.42 2.706H45.679c-2.474 0-4.616-.896-6.423-2.706-1.809-1.808-2.712-3.949-2.712-6.424V191.858c6.09 6.852 12.657 13.134 19.7 18.843 51.012 39.209 91.553 71.374 121.627 96.5 9.707 8.186 17.607 14.561 23.697 19.13 6.09 4.571 14.322 9.185 24.694 13.846 10.373 4.668 20.129 6.991 29.265 6.991h.571c9.134 0 18.894-2.323 29.263-6.991 10.376-4.661 18.613-9.274 24.701-13.846 6.089-4.569 13.99-10.944 23.698-19.13 30.074-25.126 70.61-57.291 121.624-96.5 7.043-5.708 13.613-11.991 19.694-18.843v219.267zm0-303.205v3.14c0 11.229-4.421 23.745-13.271 37.543-8.851 13.798-18.419 24.792-28.691 32.974-36.74 28.936-74.897 59.101-114.495 90.506-1.14.951-4.474 3.757-9.996 8.418-5.514 4.668-9.894 8.241-13.131 10.712-3.241 2.478-7.471 5.475-12.703 8.993-5.236 3.518-10.041 6.14-14.418 7.851-4.377 1.707-8.47 2.562-12.275 2.562h-.571c-3.806 0-7.895-.855-12.275-2.562-4.377-1.711-9.185-4.333-14.417-7.851-5.231-3.519-9.467-6.516-12.703-8.993-3.234-2.471-7.614-6.044-13.132-10.712-5.52-4.661-8.854-7.467-9.995-8.418-39.589-31.406-77.75-61.57-114.487-90.506-27.981-22.076-41.969-49.106-41.969-81.083 0-2.472.903-4.615 2.712-6.421 1.809-1.809 3.949-2.714 6.423-2.714H465.95c1.52.855 2.854 1.093 3.997.715 1.143-.385 1.998.331 2.566 2.138.571 1.809 1.095 2.664 1.57 2.57.477-.096.764 1.093.859 3.571.089 2.473.137 3.718.137 3.718v3.849h-.001z"
        />
    </svg>
)

export { Mail }
