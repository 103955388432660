// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Blog___blogIcon___GcgWs{stroke:#000;stroke-width:10}.Blog___blogIcon-text___g1s8r{font-size:130px}\n", "",{"version":3,"sources":["webpack://./src/Module/Icon/Blog/Blog.scss"],"names":[],"mappings":"AAAA,yBACI,WAAY,CACZ,eAAgB,CACnB,8BAGG,eACJ","sourcesContent":[".blogIcon {\n    stroke: #000;\n    stroke-width: 10;\n}\n\n.blogIcon-text {\n    font-size: 130px\n}"],"sourceRoot":""}]);
// Exports
export var blogIcon = "Blog___blogIcon___GcgWs";
export var blogIconText = "Blog___blogIcon-text___g1s8r";
export default ___CSS_LOADER_EXPORT___;
