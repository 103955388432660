// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Blogs___header___z73KY{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:10px}.Blogs___title___BsGzD{font-size:40px;line-height:45px}.Blogs___subtitle___fXDsW{font-size:16px}.Blogs___content___TPgjM{margin:auto;max-width:800px;font-size:16px;text-align:justify}.Blogs___blog-cta___Z8MIT{margin:20px;border:1px solid gray;border-radius:5px;padding:20px 10px;text-align:center}.Blogs___blog-cta-title___C60IG{font-weight:bold;margin-bottom:10px}.Blogs___blog-cta-description___bgHSz{font-weight:normal;font-size:12px}\n", "",{"version":3,"sources":["webpack://./src/Pages/Blogs/Blogs.scss"],"names":[],"mappings":"AAAA,wBACI,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,kBAAmB,CACnB,YAAa,CAChB,uBAGG,cAAe,CACf,gBAAiB,CACpB,0BAGG,cAAe,CAClB,yBAGG,WAAY,CACZ,eAAgB,CAChB,cAAe,CACf,kBAAmB,CACtB,0BAGG,WAAY,CACZ,qBAAsB,CACtB,iBAAkB,CAClB,iBAAkB,CAClB,iBAAkB,CAElB,gCACI,gBAAiB,CACjB,kBAAmB,CACtB,sCAGG,kBAAmB,CACnB,cAAe","sourcesContent":[".header {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n}\n\n.title {\n    font-size: 40px;\n    line-height: 45px;\n}\n\n.subtitle {\n    font-size: 16px;\n}\n\n.content {\n    margin: auto;\n    max-width: 800px;\n    font-size: 16px;\n    text-align: justify;\n}\n\n.blog-cta {\n    margin: 20px;\n    border: 1px solid gray;\n    border-radius: 5px;\n    padding: 20px 10px;\n    text-align: center;\n\n    &-title {\n        font-weight: bold;\n        margin-bottom: 10px;\n    }\n\n    &-description {\n        font-weight: normal;\n        font-size: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var header = "Blogs___header___z73KY";
export var title = "Blogs___title___BsGzD";
export var subtitle = "Blogs___subtitle___fXDsW";
export var content = "Blogs___content___TPgjM";
export var blogCta = "Blogs___blog-cta___Z8MIT";
export var blogCtaTitle = "Blogs___blog-cta-title___C60IG";
export var blogCtaDescription = "Blogs___blog-cta-description___bgHSz";
export default ___CSS_LOADER_EXPORT___;
