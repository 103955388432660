import * as React from 'react'

const Cv = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 45.057 45.057"
    >
        <path d="M19.558 25.389a1.524 1.524 0 0 1-.264.455c-.108.129-.24.229-.396.301a1.358 1.358 0 0 1-.57.107c-.313 0-.572-.068-.78-.203a1.507 1.507 0 0 1-.498-.541 2.606 2.606 0 0 1-.27-.756 4.252 4.252 0 0 1-.084-.852c0-.289.027-.572.084-.853.056-.281.146-.533.27-.756.124-.225.29-.404.498-.541.208-.137.468-.203.78-.203.271 0 .494.051.666.154.172.105.31.225.414.361.104.137.176.273.216.414.04.139.068.25.084.33h2.568c-.112-1.08-.49-1.914-1.135-2.502-.644-.588-1.558-.887-2.741-.895-.664 0-1.263.107-1.794.324-.532.215-.988.52-1.368.912-.38.392-.672.863-.876 1.416a5.262 5.262 0 0 0-.307 1.836c0 .631.097 1.223.288 1.77.192.549.475 1.021.847 1.422s.825.717 1.361.949c.536.23 1.152.348 1.849.348.624 0 1.18-.105 1.668-.312.487-.209.897-.482 1.229-.822s.584-.723.756-1.146c.172-.422.259-.852.259-1.283h-2.593c-.039.2-.092.391-.161.566zm7.062-.577h-.024l-1.404-5.196h-2.664l2.556 8.568h2.952l2.677-8.568H28z" />
        <path d="M33.431 0H5.179v45.057h34.699V6.251L33.431 0zm3.447 42.056H8.179V3h23.706v4.76h4.992l.001 34.296z" />
    </svg>
)

export { Cv }
